import "../css/source.scss";
import Carousel from "../js/carousel/controller/Carousel.js";
import ModalVideo from "modal-video";
import Collapsible from "./collapsible/Collapsible";
import Flickity from "flickity-fade";
import "flickity-as-nav-for";
import Muuri from "./muuri";
import customProductFields from "./customFields";
// import initMap from "./google-map/Map";
var content = document.getElementById("page");

jQuery(document).ready(function ($) {
  if (content) {
    // customize the color input fields
    customProductFields();

    // video modal
    videoModalConfig();

    // carousel config
    carouselConfig();

    // collapsible config
    if (document.querySelector(".faq .body .tabs-buttons .button")) {
      collapsibleConfig();
    }

    // Create collapsible content
    // Tabs Control

    // Schedule Setup
    scheduleSetup();

    // Concert Halls Setup
    concertHallsSetup();

    if (document.querySelector(".hero-slider")) {
      var options = {
        accessibility: true,
        prevNextButtons: true,
        pageDots: false,
        setGallerySize: false,
        autoPlay: 3500,
        wrapAround: true,
        arrowShape: {
          x0: 10,
          x1: 60,
          y1: 50,
          x2: 60,
          y2: 45,
          x3: 15
        }
      };

      var carousel = document.querySelector('[data-carousel]');
      var slides = document.getElementsByClassName('carousel-cell');
      var flkty = new Flickity(carousel, options);

      flkty.on('scroll', function () {
        flkty.slides.forEach(function (slide, i) {
          var image = slides[i];
          var x = (slide.target + flkty.x) * -1/3;
          image.style.backgroundPosition = x + 'px';
        });
      });
    }

    // ----------
    if (document.querySelector(".testimonials .carousel")) {
      // console.log(window.innerWidth);
      let groupCells = 3;
      if (window.innerWidth < 1200) {
        groupCells = 2;
      }
      if (window.innerWidth < 800) {
        groupCells = 1;
      }
      const testimonials = new Flickity(".testimonials .carousel", {
        prevNextButtons: false,
        draggable: false,
        pageDots: true,
        imagesLoaded: true,
        lazyLoad: true,
        groupCells: groupCells,
        adaptiveHeight: true,
      });

      // console.log(document.querySelector(".testimonials .buttons .prev"));
      document
        .querySelector(".testimonials .buttons .prev")
        .addEventListener("click", (e) => {
          e.preventDefault();
          testimonials.previous();
        });

      document
        .querySelector(".testimonials .buttons .next")
        .addEventListener("click", (e) => {
          e.preventDefault();
          testimonials.next();
        });

      $(window).load(function () {
        // console.log(testimonials);
        testimonials.resize();
      });
    }

    if (document.querySelector(".gallery-slider .carousel_main")) {
      const gallery_main = new Flickity(".gallery-slider .carousel_main", {
        pageDots: false,
      });

      const gallery_nav = new Flickity(".gallery-slider .carousel_nav", {
        asNavFor: ".carousel_main",
        contain: true,
        wrapAround: false,
        pageDots: false,
      });

      $(window).load(function () {
        gallery_main.resize();
        gallery_nav.resize();
      });

      var flkty = new Flickity(".gallery-slider .carousel_nav");
      var carouselStatus = document.querySelector(".carousel-status");

      function updateStatus() {
        var slideNumber = flkty.selectedIndex + 1;
        carouselStatus.innerHTML =
          '<span class="highlight pr-1">' +
          slideNumber +
          "</span>" +
          "/" +
          flkty.slides.length;
      }
      updateStatus();

      flkty.on("select", updateStatus);
    }

    if (
      document.querySelector(
        ".archive-concert-header .forthcoming-concerts .carousel_main"
      )
    ) {
      const testimonials = new Flickity(
        ".archive-concert-header .forthcoming-concerts .carousel_main",
        {
          prevNextButtons: false,
          draggable: true,
          pageDots: false,
          imagesLoaded: true,
          lazyLoad: true,
          groupCells: 1,
          adaptiveHeight: true
        }
      );

      // console.log(
      //   document.querySelector(
      //     ".archive-concert-header .forthcoming-concerts .buttons .prev"
      //   )
      // );
      var flkty = new Flickity(".archive-concert-header .carousel_main");

      var carouselStatus = document.querySelector(
        ".forthcoming-concerts-carousel-status"
      );

      function updateStatus() {
        var slideNumber = flkty.selectedIndex + 1;
        carouselStatus.innerHTML =
          '<span class="highlight pr-1">' +
          slideNumber +
          "</span>" +
          "/" +
          flkty.slides.length;
      }
      updateStatus();

      flkty.on("select", updateStatus);

      document
        .querySelector(
          ".archive-concert-header .forthcoming-concerts .buttons .prev"
        )
        .addEventListener("click", (e) => {
          e.preventDefault();
          if (flkty.selectedIndex > 0) {
            const forthcomingConcertsImages = Array.from(
              document.querySelectorAll(
                ".forthcoming-concerts .forthcoming-concert"
              )
            ).map((item) => {
              return item.getAttribute("data-img");
            });
            const carouselStatus = parseInt(
              document.querySelector(
                ".forthcoming-concerts-carousel-status span"
              ).textContent
            );
            // console.log(forthcomingConcertsImages);
            // console.log(carouselStatus - 1);
            document.querySelector(
              ".archive-concert-header-content"
            ).style.backgroundImage = `url(${
              forthcomingConcertsImages[carouselStatus - 2]
            })`;
            testimonials.previous();
            flkty.on("select", updateStatus);
          }
        });

      document
        .querySelector(
          ".archive-concert-header .forthcoming-concerts .buttons .next"
        )
        .addEventListener("click", (e) => {
          e.preventDefault();
          const forthcomingConcertsImages = Array.from(
            document.querySelectorAll(
              ".forthcoming-concerts .forthcoming-concert"
            )
          ).map((item) => {
            return item.getAttribute("data-img");
          });
          // console.log(flkty.selectedIndex);
          // console.log(forthcomingConcertsImages.length);
          if (flkty.selectedIndex < forthcomingConcertsImages.length - 1) {
            const carouselStatus = parseInt(
              document.querySelector(
                ".forthcoming-concerts-carousel-status span"
              ).textContent
            );
            // console.log(forthcomingConcertsImages);
            // console.log(carouselStatus);
            document.querySelector(
              ".archive-concert-header-content"
            ).style.backgroundImage = `url(${forthcomingConcertsImages[carouselStatus]})`;
            testimonials.next();
            flkty.on("select", updateStatus);
          }
        });

      $(window).load(function () {
        // console.log(testimonials);
        testimonials.resize();
      });
    }

    if (document.querySelector(".grid")) {
      var grid = new Muuri(".grid");
    }

    if (document.querySelector(".grid-choirs")) {
      var grid = new Muuri(".grid-choirs");
    }

  }
});

function videoModalConfig() {
  new ModalVideo(".js-modal-btn");
}

function carouselConfig() {
  // Mounting Phase - Create a new carousel
  const carousel = new Carousel("carousel", "list-item");
  carousel.mount();
  const temp = document.querySelector(".home .carousel-list-items");

  if (temp) {
    document
      .querySelector(".home .carousel-list-items")
      .addEventListener("click", carousel.onClickUpdate);
  }
  window.addEventListener("scroll", carousel.onScrollUpdate);
  // // carousel End
}

function collapsibleConfig() {
  new Collapsible("collapsible");

  const buttons = Array.from(
    document.querySelectorAll(".faq .body .tabs-buttons .button")
  );
  let activeButton = null;
  buttons.map((button) => {
    activeButton = button;
    button.addEventListener("click", (e) => {
      if (activeButton) {
        activeButton.style.backgroundColor = "#f7f7f7";
        activeButton.style.color = "#000";
      }
      button.style.backgroundColor = "#e9121b";
      button.style.color = "#fff";
      activeButton = button;
      // console.log(button.style);
      // console.log(e.target.getAttribute("data-tab"));
      // console.log("button clicked");
      let i;
      let x = document.querySelectorAll(".category");
      // console.log(x);
      for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
      }
      document.getElementById(e.target.getAttribute("data-tab")).style.display =
        "flex";
    });
  });
  buttons[0].dispatchEvent(new Event("click"));
}

const scheduleSetup = () => {
  if (document.querySelector(".schedule-result")) {
    // console.log("Schedule Start -----");
    const months = Array.from(
      document.querySelectorAll(".schedule-result .month")
    );
    months.map((month) => {
      month.addEventListener("click", (e) => {
        const targetedMonth = Array.from(
          e.target.closest(".items .header .month").children
        );
        const targetedIcon = targetedMonth[1];
        const targetedIconClassList = Array.from(targetedMonth[1].classList);
        if (targetedIconClassList.includes("fa-angle-up")) {
          targetedMonth[1].classList.remove("fa-angle-up");
          targetedMonth[1].classList.add("fa-angle-down");
        } else {
          targetedMonth[1].classList.remove("fa-angle-down");
          targetedMonth[1].classList.add("fa-angle-up");
        }

        let weeks = Array.from(e.target.closest(".items").children);
        weeks = weeks.slice(1, weeks.length);
        // console.log(weeks);
        weeks.map((week) => {
          week.classList.toggle("d-none");
        });

        const allIconUp = Array.from(
          document.querySelectorAll(".schedule-result .month .fa-angle-up")
        );
        const allIconUpLength = allIconUp.length;
        if (allIconUpLength === 0)
          document.querySelector(".schedule-result .year-date").style.display =
            "none";
        else
          document.querySelector(".schedule-result .year-date").style.display =
            "block";
      });
    });
    // console.log(months);
    // console.log("Schedule End -----");
  }
};

const concertHallsSetup = () => {
  if (document.querySelector(".concert-halls")) {
    // console.log("Concert Halls Start -----");
    const items = Array.from(
      document.querySelectorAll(".concert-halls .collapsible-items .item")
    );

    items.map((item) => {
      item.addEventListener("click", (e) => {
        const currentHallImage = document.querySelector(
          ".concert-halls .item-event"
        );
        currentHallImage.style.backgroundImage = `url(${item.getAttribute(
          "data-hall-image"
        )})`;
        currentHallImage.querySelector(
          ".hall-name"
        ).textContent = item.getAttribute("data-hall-name");
        currentHallImage.querySelector(
          ".hall-address"
        ).textContent = item.getAttribute("data-hall-address");
        // console.log(item);
      });
    });
    setTimeout(() => {
      // console.log(items[0]);
      items[0].dispatchEvent(new Event("click"));
    }, 100);

    // console.log("Concert Halls End -----");
  }
};

jQuery(document).ready(function ($) {
  var content = document.getElementById("page");
  if (content) {
    /*************************/
    /** Sticky Scroll Class **/
    /*************************/

    var mobile = 1024;

    if ($(window).width() > mobile) {
      window.onscroll = function () {
        stickyMenu();
      };
    }

    var offset = content.offsetTop + 50;

    function stickyMenu() {
      if (window.pageYOffset > offset) {
        $("body").addClass("scroll");
      } else {
        $("body").removeClass("scroll");
      }
    }

    /*****************/
    /** Mobile Menu **/
    /*****************/

    $(".mobile.toggle").click(function () {
      $("body").toggleClass("open");
    });

    $(".navigation a").click(function () {
      $("body").removeClass("open");
    });

    function removeMobileMenu() {
      if ($(window).width() > mobile) {
        $("body").removeClass("open");
      }
    }

    removeMobileMenu();

    $(window).resize(function () {
      removeMobileMenu();
    });
  }

  /*************/
  /** Filters **/
  /*************/

  var filter = document.getElementsByClassName("schedule-filter");

  if (filter) {
    $(".schedule-filter .toggleFilter").click(function(){
      $('.schedule-filter').toggleClass("open");
    });
  }

});
