import Link from "./Link";

class Links {
  constructor() {
    this.links = [];
  }

  // API
  getLinks = () => this.links;
  getLink = (linkTo) => this.links.find((item) => item.linkTo === linkTo);

  addLink = (linkTo, isActive, name) => {
    this.links.push(new Link(linkTo, isActive, name));
  };

  updateLinkIsActive = (linkTo, isActive) => {
    const linkToUpdate = this.links.find((item) => item.linkTo === linkTo);
    linkToUpdate.setIsActive(isActive);
  };
  deleteLink = (linkTo) =>
    (this.links = this.links.filter((item) => item.linkTo !== linkTo));
}

export default Links;
