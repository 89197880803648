import Sections from "./section/Sections";
import Links from "./link/Links";
import History from "./history./History";

/*
  Carousel Model: External API for updating the carousel and its respective sections data.
*/

class CarouselModel {
  constructor() {
    this.sections = new Sections();
    this.links = new Links();
    this.history = new History();
  }

  // Sections API
  getSections = () => this.sections.getSections();
  getSection = (sectionID) => this.sections.getSection(sectionID);

  addSection = (id, name, isActive, top, bottom) => {
    this.sections.addSection(id, name, isActive, top, bottom);
  };
  updateSectionName = (sectionID, name) => {
    this.sections.updateSectionName(sectionID, name);
  };
  updateSectionIsActive = (sectionID, isActive) => {
    this.sections.updateSectionIsActive(sectionID, isActive);
  };

  updateSectionTop = (sectionID, top) => {
    this.sections.updateSectionTop(sectionID, top);
  };

  updateSectionBottom = (sectionID, bottom) => {
    this.sections.updateSectionBottom(sectionID, bottom);
  };

  deleteSection = (sectionID) => this.sections.deleteSection(sectionID);

  // Links API
  getLinks = () => this.links;
  getLink = (linkTo) => this.links.getLink(linkTo);

  addLink = (linkTo, isActive, name) => {
    this.links.addLink(linkTo, isActive, name);
  };

  updateLinkIsActive = (linkTo, isActive) => {
    this.links.updateLinkIsActive(linkTo, isActive);
  };
  deleteLink = (linkTo) => this.links.deleteLink(linkTo);

  // History API

  updateHistory = (activeLink) => {
    this.history.update(activeLink);
  };

  getHistoryCurrent = () => this.history.getCurrent();

  getHistoryPrevious = () => this.history.getPrevious();

  getHistoryIsFirstCall = () => this.history.getIsFirstCall();
}

export default CarouselModel;
