class Link {
  constructor(linkTo, isActive, name) {
    this.linkTo = this.setLinkTo(linkTo);
    this.isActive = this.setIsActive(isActive);
    this.name = this.setName(name);
  }

  getLinkTo = () => this.linkTo;
  setLinkTo = (linkTo) => (this.linkTo = linkTo);

  getIsActive = () => this.isActive;
  setIsActive = (isActive) => (this.isActive = isActive);

  getName = () => this.name;
  setName = (name) => (this.name = name);
}

export default Link;
