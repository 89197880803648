class Collapsible {
  constructor(collapsibleButton) {
    this.collapsibleButton = collapsibleButton;
    this.run();
    // console.log("WORKS")
  }

  run() {
    let coll = document.getElementsByClassName(this.collapsibleButton);
    let i;

    for (i = 0; i < coll.length; i++) {
      // console.log("WORKS")
      coll[i].addEventListener("click", function () {
        this.classList.toggle("active");
        let content = this.nextElementSibling;
        if (content.style.maxHeight) {
          content.style.maxHeight = null;
        } else {
          content.style.maxHeight = content.scrollHeight + "px";
        }
      });
    }
  }
}

export default Collapsible;
