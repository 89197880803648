import CarouselModel from "../models/CarouselModel";

/*
  Carousel Controller:  control the carousel Data model and the UI
  inputs: 
        linksParentSelector : should implement data-carousel-links=x to the carousel parent 
        + should implement data-section-id|=x-id AND data-active="false OR true" AND data-name=x . for each section to be mapped to carousel.
*/

class Carousel {
  constructor(linksParentSelector, sectionsSelector) {
    this.linksParentSelector = linksParentSelector;
    this.sectionsSelector = sectionsSelector;
    this.carouselModel = new CarouselModel();
    this.linksParentUI = null;
    this.sectionsParentUI = null;
    this.isCarouselRendered = false;
    this.isScrollingActivated = false;
  }

  mount = () => {
    // get linksParentUI : One
    this.linksParentUI = document.querySelector(
      `[data-carousel-links='${this.linksParentSelector}']`
    );
    // get sectionsUI : All AND convert into Array
    this.sectionsParentUI = Array.from(
      document.querySelectorAll(`[data-section-id|='${this.sectionsSelector}']`)
    );

    // loop through each sectionUI
    this.sectionsParentUI.map((section) => {
      //    grab id, name, isActive
      const id = section.getAttribute("data-section-id");
      const name = section.getAttribute("data-name");
      const isActive =
        section.getAttribute("data-active") === "true" ? true : false;
      //    calculate section top, bottom
      const top = section.offsetTop;
      const bottom = section.offsetTop + section.scrollHeight;
      //    add section to carousel model via api
      this.carouselModel.addSection(id, name, isActive, top, bottom);
      //    add link to carousel model
      this.carouselModel.addLink(id, isActive, name);
    });

    // render carousel list Model to UI
    this.carouselModel.links.getLinks().map((link) => {
      this.linksParentUI.innerHTML += `
        <div class="pb-1" data-active=carousel-item${
          link.getIsActive() ? "-active" : ""
        }>
          <i class="fas fa-square " data-to=${link.getLinkTo()} style="cursor: pointer; font-size: 0.75rem; padding: 1em 0; text-transform: uppercase;"><strong>${link.getName()}</strong></i>
        </div>
      `;
    });
    this.isCarouselRendered = true;
  };

  onClickUpdate = (e) => {
    // get the target clicked element
    const clickedTarget = e.target;
    this.isScrollingActivated = true;

    // check if tagName is icon
    if (clickedTarget.tagName === "I") {
      // console.log(e.target);
      setTimeout(() => {
        this.isScrollingActivated = false;
      }, 1000);

      // check if first time activation link
      if (this.carouselModel.history.getIsFirstCall()) {
        // get the icon linTo data
        const linkTo = clickedTarget.getAttribute("data-to");

        // update the link modal
        this.carouselModel.links.updateLinkIsActive(linkTo, true);

        // update the section modal
        this.carouselModel.sections.updateSectionIsActive(linkTo, true);

        // update the history
        this.carouselModel.updateHistory(linkTo);

        // get the clicked link from history
        const currentActiveLinkFromHistory = this.carouselModel.getHistoryCurrent();

        // update section UI
        document
          .querySelector(`[data-section-id='${currentActiveLinkFromHistory}']`)
          .setAttribute("data-active", "true");

        // update link UI

        clickedTarget.parentElement.setAttribute(
          "data-active",
          "carousel-item-active"
        );

        // scrolling to the appropriate section
        document
          .querySelector(`[data-section-id=${currentActiveLinkFromHistory}]`)
          .scrollIntoView();
      } else {
        // get the icon linTo data
        const linkTo = clickedTarget.getAttribute("data-to");

        // update the history
        this.carouselModel.updateHistory(linkTo);

        // get the clicked link from history
        const currentActiveLinkFromHistory = this.carouselModel.getHistoryCurrent();

        // get the previous link from history
        const previousActiveLinkFromHistory = this.carouselModel.getHistoryPrevious();

        // update the link modal
        this.carouselModel.links.updateLinkIsActive(
          previousActiveLinkFromHistory,
          false
        );

        this.carouselModel.links.updateLinkIsActive(
          currentActiveLinkFromHistory,
          true
        );

        // update the section modal
        this.carouselModel.sections.updateSectionIsActive(
          previousActiveLinkFromHistory,
          false
        );

        this.carouselModel.sections.updateSectionIsActive(
          currentActiveLinkFromHistory,
          true
        );

        // update section UI
        document
          .querySelector(`[data-section-id='${previousActiveLinkFromHistory}']`)
          .setAttribute("data-active", "false");

        document
          .querySelector(`[data-section-id='${currentActiveLinkFromHistory}']`)
          .setAttribute("data-active", "true");

        // update link UI
        document
          .querySelector(`[data-to=${previousActiveLinkFromHistory}]`)
          .parentElement.setAttribute("data-active", "carousel-item");

        clickedTarget.parentElement.setAttribute(
          "data-active",
          "carousel-item-active"
        );

        // scrolling to the appropriate section
        document
          .querySelector(`[data-section-id=${currentActiveLinkFromHistory}]`)
          .scrollIntoView();
      }
    }
  };

  onScrollUpdate = (e) => {
    // get the target clicked element
    const clickedTarget = e.target;

    if (this.isScrollingActivated) return;

    if (this.isCarouselRendered) {
      // get the sections model and loop through it
      this.carouselModel.getSections().map((section) => {
        //    if the scrolling position exceed a section top

        if (
          clickedTarget.scrollingElement.scrollTop > section.getTop() &&
          clickedTarget.scrollingElement.scrollTop < section.getBottom()
        ) {
          const time = setTimeout(() => {
            //  check if the element id is equal to the history current link id => if so, return
            const currentActiveLinkFromHistory = this.carouselModel.getHistoryCurrent();

            if (currentActiveLinkFromHistory === section.getID()) return;

            //  else
            //  update the history
            //  check if the history has prev
            if (this.carouselModel.getHistoryIsFirstCall()) {
              //  if not update current
              this.carouselModel.updateHistory(section.getID());

              //  update section model
              this.carouselModel.sections.updateSectionIsActive(
                section.getID(),
                true
              );

              //  update list model
              this.carouselModel.links.updateLinkIsActive(
                section.getID(),
                true
              );

              //  update section UI
              document
                .querySelector(`[data-section-id='${section.getID()}']`)
                .setAttribute("data-active", "true");

              //  update list UI
              document
                .querySelector(`[data-to=${section.getID()}]`)
                .parentElement.setAttribute(
                  "data-active",
                  "carousel-item-active"
                );

              //  return
              return;
            } else {
              //   same process, but update previous and current model, ui, and history
              //  if not update current
              this.carouselModel.updateHistory(section.getID());

              // get the clicked link from history
              const currentActiveLinkFromHistory = this.carouselModel.getHistoryCurrent();

              // get the previous link from history
              const previousActiveLinkFromHistory = this.carouselModel.getHistoryPrevious();

              //  update section model
              this.carouselModel.sections.updateSectionIsActive(
                currentActiveLinkFromHistory,
                true
              );

              this.carouselModel.sections.updateSectionIsActive(
                previousActiveLinkFromHistory,
                false
              );

              //  update list model
              this.carouselModel.links.updateLinkIsActive(
                currentActiveLinkFromHistory,
                true
              );

              this.carouselModel.links.updateLinkIsActive(
                previousActiveLinkFromHistory,
                false
              );

              //  update section UI
              document
                .querySelector(
                  `[data-section-id='${currentActiveLinkFromHistory}']`
                )
                .setAttribute("data-active", "true");

              document
                .querySelector(
                  `[data-section-id='${previousActiveLinkFromHistory}']`
                )
                .setAttribute("data-active", "false");

              //  update list UI
              document
                .querySelector(`[data-to=${currentActiveLinkFromHistory}]`)
                .parentElement.setAttribute(
                  "data-active",
                  "carousel-item-active"
                );

              document
                .querySelector(`[data-to=${previousActiveLinkFromHistory}]`)
                .parentElement.setAttribute("data-active", "carousel-item");
            }
          }, 100);
        }
      });
    }
  };
}

export default Carousel;
