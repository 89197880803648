const customProductFields = () => {
  // customize the color inputs
  //console.log("customProductFields Ready");
  customColorField();

  // customQuantityField();

  customSizeField();

  customImages();

  setTimeout(() => {
    Array.from(document.querySelectorAll(".flex-control-nav li")).map(
      (thumbnails) => {
        thumbnails.addEventListener("click", (e) => {
          setTimeout(() => {
            // console.log(document.querySelector(".flex-viewport").style.height);
            document.querySelector(".flex-viewport").style.height = "600px";
            // console.log(document.querySelector(".flex-viewport").style.height);
            document.querySelector(".flex-viewport").style.height = "600px";
          }, 200);
          e.target.dispatchEvent(new Event("click"));
        });
      }
    );
  }, 1000);
};

// custom images
const customImages = () => {};

// custom color filed
const customColorField = () => {
  if (document.querySelector(".attribute-color .value div")) {
    // add the color name dynamically
    // add a span element for storing color name
    const colorsUI = Array.from(
      document.querySelectorAll(".attribute-color .value div")
    );
    colorsUI[1].insertAdjacentHTML(
      "afterend",
      "<span class='color-text-name'></span>"
    );

    // get the color labels
    const colorLabels = Array.from(
      document.querySelectorAll(".attribute-color .value label")
    );
    // console.log(colorLabels);

    let prevColor = null;
    let currentColor = null;

    colorLabels.map((label) => {
      // change the label background color
      label.style.backgroundColor = label.parentElement.firstChild.getAttribute(
        "value"
      );
      label.textContent = "";

      // add the on click event
      label.addEventListener("click", (e) => {
        prevColor && prevColor.classList.remove("active");
        // un-check the input
        prevColor &&
          prevColor.parentElement.firstChild.setAttribute("checked", "false");

        currentColor = label;
        // add active class to the label
        currentColor.classList.add("active");
        // check the input
        currentColor.parentElement.firstChild.setAttribute("checked", "true");

        prevColor = currentColor;

        // add the color text
        // console.log(currentColor.textContent);
        document.querySelector(
          ".attribute-color .value span"
        ).textContent = currentColor.parentElement.firstChild.getAttribute(
          "value"
        );
      });
    });
  }
};

// custom size field
const customSizeField = () => {
  if (document.querySelector(".attribute-size .value label")) {
    let prevSize = null;
    let currentSize = null;

    const sizeButtons = Array.from(
      document.querySelectorAll(".attribute-size .value label")
    );

    sizeButtons.map((button) => {
      button.addEventListener("click", (e) => {
        prevSize && prevSize.classList.remove("active");
        // un-check the input
        prevSize &&
          prevSize.parentElement.firstChild.setAttribute("checked", "false");

        currentSize = button;
        // add active class to the label
        currentSize.classList.add("active");
        // check the input
        currentSize.parentElement.firstChild.setAttribute("checked", "true");

        prevSize = currentSize;
      });
    });
  }
};

// custom quantity field
// const customQuantityField = () => {
//   if (document.querySelector("[name=quantity]")) {
//     // get the input filed
//     let inputUI = document.querySelector("[name=quantity]");
//     // console.log(inputUI);

//     // remove the incr and decr buttons from inside
//     inputUI.setAttribute("type", "");

//     // set the input into readonly
//     inputUI.setAttribute("readOnly", true);

//     // add decrement button before of the input
//     inputUI.insertAdjacentHTML(
//       "beforebegin",
//       "<button class='decrement-quantity'>-</button>"
//     );

//     // add increment button before of the input
//     inputUI.insertAdjacentHTML(
//       "afterend",
//       "<button class='increment-quantity'>+</button>"
//     );

//     // add event listeners to buttons
//     // decrement
//     const decrementButton = document.querySelector(".decrement-quantity");
//     decrementButton.addEventListener("click", (e) => {
//       e.preventDefault();

//       let count = parseInt(inputUI.getAttribute("value"));
//       const min = inputUI.getAttribute("min");

//       if (count > min) {
//         count--;
//         inputUI.setAttribute("value", count);
//       }
//     });

//     // increment
//     const incrementButton = document.querySelector(".increment-quantity");
//     incrementButton.addEventListener("click", (e) => {
//       e.preventDefault();

//       let count = parseInt(inputUI.getAttribute("value"));
//       const max = inputUI.getAttribute("max") || 99;
//       // console.log(count, max);

//       if (count < max) {
//         count++;
//         inputUI.setAttribute("value", count);
//       }
//     });
//   }
// };

export default customProductFields;
