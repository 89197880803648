import Sections from "./Sections";

class Section {
  constructor(id, name, isActive, top, bottom) {
    this.id = this.setID(id);
    this.name = this.setName(name);
    this.isActive = this.setIsActive(isActive);
    this.top = this.setTop(top);
    this.bottom = this.setBottom(bottom);
  }

  getID = () => this.id;
  setID = (id) => (this.id = id);

  getName = () => this.name;
  setName = (name) => (this.name = name);

  getIsActive = () => this.isActive;
  setIsActive = (isActive) => (this.isActive = isActive);

  getTop = () => this.top;
  setTop = (top) => (this.top = top);

  getBottom = () => this.bottom;
  setBottom = (bottom) => (this.bottom = bottom);
}

export default Section;
