import Section from "./Section";

class Sections {
  constructor() {
    this.sections = [];
  }

  // API
  getSections = () => this.sections;
  getSection = (sectionID) =>
    this.sections.find((item) => item.id === sectionID);

  addSection = (id, name, isActive, top, bottom) => {
    this.sections.push(new Section(id, name, isActive, top, bottom));
  };
  updateSectionName = (sectionID, name) => {
    const sectionToUpdate = this.sections.find((item) => item.id === sectionID);
    sectionToUpdate.setName(name);
  };
  updateSectionIsActive = (sectionID, isActive) => {
    const sectionToUpdate = this.sections.find((item) => item.id === sectionID);
    sectionToUpdate.setIsActive(isActive);
  };

  updateSectionTop = (sectionID, top) => {
    const sectionToUpdate = this.sections.find((item) => item.id === sectionID);
    sectionToUpdate.setIsTop(isActive);
  };

  updateSectionBottom = (sectionID, bottom) => {
    const sectionToUpdate = this.sections.find((item) => item.id === sectionID);
    sectionToUpdate.setIsBottom(bottom);
  };

  deleteSection = (sectionID) =>
    (this.sections = this.sections.filter((item) => item.id !== sectionID));
}

export default Sections;
