/*
  Carousel History: keep track the previous carousel link, and the current active one 
  inputs: (prev carousel data-to attribute, current carousel data-to attribute
  output: previous and current data-to attribute
*/

class History {
  constructor() {
    this.previous = null;
    this.current = null;
    this.isFirstCall = true;
  }

  // add current carousel link data-to attribute
  update = (activeLink) => {
    if (this.isFirstCall) {
      // console.log("History - First Call");
      this.current = activeLink;
      this.isFirstCall = false;
    } else {
      this.swap(activeLink);
    }
  };

  // swap the current with prev
  swap = (activeLink) => {
    const temp = this.current;
    this.current = activeLink;
    this.previous = temp;
  };

  // getter
  getPrevious = () => this.previous;
  getCurrent = () => this.current;
  getIsFirstCall = () => this.isFirstCall;
}

export default History;
